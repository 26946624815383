import { useEffect, useRef } from "react";

// Lib
import { Helmet } from "react-helmet";
import axios, { AxiosError } from "axios";
import AnalyticsService from "analytics-web";
import SlideRoutes from "react-slide-routes";
import { useErrorHandler } from "react-error-boundary";

// Components

// Utils
import i18n from "./i18n";

// Services
import useOnlineStatus from "./hooks/useOnlineStatus";

// Type Definitions
import default_theme from "../public/config/defaultpage.json";
import nygh_theme from "../public/config/nygh.json";
import { useApp } from "./context/AppContext";
import useManageLocalizationFailure from "./hooks/useManageLocalizationFailure";
import { LocationSelectionConfig } from "./components/StaticWidgets/LOCATION_SELECTION";
import { SearchBarConfig } from "./components/StaticWidgets/SEARCH_BAR";
import { ProfileConfig } from "./components/StaticWidgets/PROFILE_PREFERENCES";
import { initializeNativePostMessageService } from "./services/NativePostMessage";
import RouteCreationService from "./services/RouteCreationService";
import useFlows from "./hooks/useFlows";
import ModalCreationService from "./services/ModalCreationService";
import useOnboardingRedirect from "./hooks/useOnboardingRedirect";

export type ThemeProps = typeof default_theme & typeof nygh_theme;

declare global {
  interface Window {
    config: any;
    webkit: any;
    APP_COMPONENT: any;
    GLOBAL_PATH: any;
    Android: any;
  }
}
const App = () => {
  const {
    isAppReady,
    setIsAppReady,
    isAnalyticsInitialized,
    setIsAnalyticsInitialized,
    addConfig,
    configs,
  } = useApp();
  const handleError = useErrorHandler();
  const { onlineStatus } = useOnlineStatus();

  // <--- Handle Errors --->

  // Axios Error Handler
  axios.interceptors.response.use(
    (res) => res,
    (error: AxiosError) =>
      handleError({
        message: error.message,
        error_code: "ERROR_API_FAILURE",
        error_response: error.response,
      })
  );

  // Manage Localization Failure
  useManageLocalizationFailure();

  // OnlineStatus Handler
  useEffect(() => {
    if (onlineStatus === "offline") {
      handleError({
        message: "Network Connection Lost",
        error_code: "ERROR_NO_NETWORK",
      });
    }
  }, [onlineStatus, handleError]);

  // Focus Handler
  useEffect(() => {
    const handleFocusIn = (event: FocusEvent) => {
      const target = event.target as HTMLElement;

      if (target && typeof target.scrollIntoView === "function") {
        setTimeout(() => {
          target.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 0); // small delay to prevent skipping
      }
    };

    document.addEventListener("focusin", handleFocusIn);

    return () => {
      document.removeEventListener("focusin", handleFocusIn);
    };
  }, []);

  // Ref for sidebar
  const childRef = useRef({});
  window.APP_COMPONENT = childRef;

  useEffect(() => {
    const startupActions = () => {
      const startup = [];

      const location_args = window.location.host.split(".");
      const host_prefix = location_args[0];

      startup.push(
        new Promise((resolve, reject) => {
          axios.get("./config/config.json").then((res) => {
            window.config = res.data;

            const headID = document.getElementsByTagName("head")[0];
            const link = document.createElement("link");
            link.type = "text/css";
            link.rel = "stylesheet";

            headID.appendChild(link);

            if (window.config.remote_prefix) {
              link.href = `${window.config.remote_prefix}/${host_prefix}/whitelabel.css`;
            } else if (window.config.whitelabel) {
              link.href = window.config.whitelabel;
            }

            resolve(res.data);
          });
        })
      );

      return Promise.all(startup).then(async (res: Array<any>) => {
        // Get the Content for Listing Page
        let content;

        const { language } = navigator;
        const langCode = `-${language.substring(0, 2)}`;
        const langContent = (url) => url.replace("content.json", `content${langCode}.json`);
        const content_url = res?.[0]?.content_url;

        const url = window.config.remote_prefix
          ? `${window.config.remote_prefix}/${host_prefix}/content.json`
          : content_url;

        if (url) {
          const contentLang = langContent(url);
          content = await axios.get(contentLang);
          if (!content) {
            console.log(`no content${langCode}.json file found`);
            content = await axios.get(url);
          }
        }

        window.config = { ...window.config, ...content.data };
        if (!content.data.pages) {
          console.log("content.json missing `pages` key");
        }

        // Call the Native PostMessage Service before everything else
        initializeNativePostMessageService();

        // Add Static Widget Configs, and OnboardingModules
        addConfig("StaticWidgets", [LocationSelectionConfig(), SearchBarConfig(), ProfileConfig()]);
        addConfig("FlowModule", useFlows());

        setIsAppReady(true);
      });
    };

    startupActions();
  }, [setIsAppReady, handleError]);

  // Analytics
  if (window.config && !isAnalyticsInitialized) {
    AnalyticsService.initialize(window.config.analytics_config.alias, {
      instrumentationKey: window.config.analytics_config.instrumentationKey,
      disableAjaxTracking: true,
    });

    setIsAnalyticsInitialized(true);
  }

  useOnboardingRedirect(configs);

  if (!isAppReady) {
    return <div />;
  }

  const noscriptText = i18n.t("noscript");

  return (
    <div className="App">
      <Helmet>
        <meta charSet={i18n.t("charset")} />
        <link href={i18n.t("favicon")} />
        <link rel="shortcut icon" href={window.config.favicon} />
        <noscript>{noscriptText.toString()}</noscript>
      </Helmet>
      <div className="app-wrapper">
        {/* removed <main> tag for accessibility purposes; re-add if needed */}
        <div className="main-content">
          <SlideRoutes timing="ease-in-out" duration={300}>
            {isAppReady && RouteCreationService.createListingPageRoute()}
            {isAppReady && RouteCreationService.createRoutes(configs.staticWidgets)}
          </SlideRoutes>
          {isAppReady &&
            window.config.flows &&
            RouteCreationService.createOnboardingRoutes(configs.flowModule.before, true)}
          {window.config.flows && ModalCreationService.createModals(configs.flowModule.after)}
        </div>
      </div>
    </div>
  );
};

export default App;

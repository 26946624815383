import { Route, useLocation } from "react-router-dom";
import SlideRoutes from "react-slide-routes";
import { ConfigType } from "../context/AppContext";
import StandardLayout from "../components/Layout/StandardLayout";
import ListingPage from "../components/ListingPage";
import { OnboardingNavBars } from "../components/OnboardingModules";

class RouteCreationService {
  static generatePathAndKey(Component, j, useIncrementalPath = false, basePath = "") {
    const identifier = useIncrementalPath ? j.toString() : Component.name;
    const path = `${basePath}${window.GLOBAL_PATH}${identifier}`;
    const key = `${identifier}-${j}`;

    return { path, key };
  }

  static createRoutes(configs: Array<ConfigType>, useIncrementalPath = false) {
    return configs.map((config) =>
      config.components.map(({ Component }, j) => {
        // console.log(config);
        const { path, key } = this.generatePathAndKey(
          Component,
          j,
          useIncrementalPath,
          config.name
        );
        return (
          <Route
            key={key}
            path={path}
            element={
              // @ts-ignore
              <Component />
            }
          />
        );
      })
    );
  }

  static createOnboardingRoutes(configs: Array<ConfigType>, useIncrementalPath = false) {
    const routes = [];
    const whiteList = new Set();

    const { buttonStyle } = configs[0];
    // console.log(configs[0].buttonStyle);

    configs.forEach((config) => {
      config.components.forEach(({ Component }, j) => {
        const { path, key } = this.generatePathAndKey(
          Component,
          j,
          useIncrementalPath,
          config.name
        );

        whiteList.add(`/${path}`); // Adding the path to the whitelist

        routes.push(
          <Route
            key={key}
            path={path}
            element={<Component />} // @ts-ignore
          />
        );
      });
    });

    const location = useLocation();
    const currentPath = location.pathname;
    const shouldShowOnboardingNavBars = whiteList.has(currentPath);

    // console.log(
    //   "whitelist: ",
    //   whiteList,
    //   "currentPath: ",
    //   currentPath,
    //   "shouldShowOnboardingNavBars: ",
    //   shouldShowOnboardingNavBars
    // );

    return (
      <div>
        {/* other components */}
        {shouldShowOnboardingNavBars ? (
          <OnboardingNavBars buttonStyle={buttonStyle}>
            <SlideRoutes timing="ease-in-out" duration={300}>
              {routes}
            </SlideRoutes>
          </OnboardingNavBars>
        ) : (
          <></>
        )}
      </div>
    );
  }

  static createListingPageRoute() {
    return (
      <>
        <Route
          path={`${window.GLOBAL_PATH}:route_id`}
          element={
            <StandardLayout>
              <ListingPage />
            </StandardLayout>
          }
        />
        <Route
          path={window.GLOBAL_PATH}
          element={
            <StandardLayout>
              <ListingPage />
            </StandardLayout>
          }
        />
      </>
    );
  }
}

export default RouteCreationService;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDrag } from "@use-gesture/react";
import {
  getOnboardingPageName,
  sendAnalytics,
  postOnboardingActions,
} from "../../utils/CommonFunctions";
import { useApp } from "../../context/AppContext";
import parseNestedUrl from "../../utils/parseNestedUrl";
import BottomBar from "./BottomBar";
import TopBar from "./TopBar";
import useAddCompletedOnboardingFlow from "./hooks/useAddCompletedOnboardingFlow";

interface OnboardingPageProps {
  children?: React.ReactNode;
  title?: string;
  onNext?: () => void;
  buttonStyle?: string;
}

const OnboardingNavBars = ({ children, buttonStyle }: OnboardingPageProps) => {
  const { configs } = useApp();
  const addFlow = useAddCompletedOnboardingFlow();

  const [nextPage, setNextPage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount] = useState(configs.flowModule.before[0].components.length - 1);
  const navigate = useNavigate();
  const location = useLocation();

  const locationPath = location.pathname;
  const pageName = getOnboardingPageName(locationPath);

  const swipeThreshold = 10;
  const containerRef = useRef(null);

  const bind = useDrag(({ down, movement: [mx], velocity }) => {
    if (!down && Math.abs(mx) > swipeThreshold && velocity[0] > 0.01) {
      // Check if the user is not on the last page before allowing swipe to next
      if (mx < 0 && currentPage <= pageCount) {
        handleNext();
      } else if (mx > 0) {
        handleBack();
      }
    }
  }, {});

  const handleNavigate = (path) => {
    navigate(path);
  };

  const scrollToTopOfNestedDiv = () => {
    const container = containerRef.current;
    if (container) {
      const nestedDiv = container.querySelector(".slide-routes");
      if (nestedDiv) {
        nestedDiv.scrollTop = 0;
      }
    }
  };

  const handleNext = () => {
    scrollToTopOfNestedDiv();
    handleNavigate(nextPage);
    sendAnalytics("Next", pageName);
  };

  const handleBack = () => {
    scrollToTopOfNestedDiv();
    handleNavigate(-1);
    sendAnalytics("Back", pageName);
  };

  useEffect(() => {
    const { basePathname, currentPageNumber } = parseNestedUrl(location.pathname);

    if (Number.isNaN(currentPageNumber)) {
      return;
    }

    setCurrentPage(currentPageNumber + 1); // Set current page number state

    if (currentPageNumber < pageCount) {
      setNextPage(`/${basePathname}/${currentPageNumber + 1}`);
    } else {
      setNextPage(`/${basePathname}`);
      addFlow("before");
    }
  }, [pageCount, nextPage, location.pathname]);

  const handleSkip = () => {
    sendAnalytics("Skip", pageName);
    addFlow("before");
    postOnboardingActions();
  };

  return (
    <div className="onboarding" {...bind()}>
      <TopBar onSkip={handleSkip} />
      <div ref={containerRef} className="onboarding__page">
        {children}
      </div>
      <BottomBar
        onNext={currentPage < pageCount + 1 ? handleNext : undefined}
        stepProgress={currentPage}
        maxSteps={pageCount + 1}
        onBack={currentPage > 1 ? handleBack : undefined}
        buttonStyle={buttonStyle}
      />
    </div>
  );
};

export default OnboardingNavBars;

import React, { useState } from "react";

// Lib
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

// Components
import RowItems from "./RowItem";
import useDrag from "../../utils/useDrag";

// Utils
import { handleOverrideClassName, handleOverrideConfig } from "../../utils/CommonFunctions";
import addTile from "./rowHelper";

// Interfaces
import { theme, contentRow, rowItem } from "../../ComponentInterface";

interface Props {
  theme: theme;
  pageContent: contentRow;
  pageName?: string;
}

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const ScrollableRow = ({ pageContent, theme, pageName }: Props) => {
  const [activeItemID, setActiveItemID] = useState("0");

  // Methods for mouse Dragging

  const { dragStart, dragStop, dragMove } = useDrag();

  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const handleRenderDots = () => {
    const resultDiv = [];
    let tempClassname = "dot";

    for (let i = 0; i < pageContent.items?.length; i++) {
      if (activeItemID === String(i)) {
        tempClassname = "active dot";
      } else {
        tempClassname = "dot";
      }

      resultDiv.push(<div key={i} className={tempClassname} />);
    }

    return resultDiv;
  };

  const allItems: object[] = Array.isArray(pageContent.items) ? pageContent.items : [];
  const itemsToDisplay = pageContent?.additional_tile ? addTile(pageContent, allItems) : allItems;

  if (!itemsToDisplay.length) return null;

  const numberClass = `block-${pageContent?.items?.length}`;

  return (
    <div>
      <section
        className={`row-container ${handleOverrideClassName(
          pageContent.rowContainerClassname
        )} ${numberClass}`}
      >
        <ScrollMenu
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
        >
          {itemsToDisplay.map((item: rowItem, i: number) => (
            <RowItems
              key={String(i)}
              rowItem={item}
              theme={theme}
              pageName={pageName}
              itemId={String(i)}
              setActiveItemID={setActiveItemID}
            />
          ))}
        </ScrollMenu>
      </section>

      {handleOverrideConfig(theme.row.showDots, pageContent.showDots) === false ||
        (pageContent.items?.length > 1 && <div className="dots">{handleRenderDots()}</div>)}
    </div>
  );
};

export default ScrollableRow;

import React, { useEffect } from "react";

// Lib
import { useNavigate } from "react-router-dom";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

// Components
import FC1 from "../DynamicComponents/FC1";
import FC2 from "../DynamicComponents/FC2";
import FC3 from "../DynamicComponents/FC3";
import FC4 from "../DynamicComponents/FC4";
import IC1 from "../DynamicComponents/IC1";
import IC2 from "../DynamicComponents/IC2";
import PI1 from "../DynamicComponents/PI1";
import PI2 from "../DynamicComponents/PI2";
import A1 from "../DynamicComponents/A1";
import Twitter from "../DynamicComponents/Twitter";
import Version from "../DynamicComponents/Version";
import CarePlanWidget from "../DynamicComponents/CarePlanWidget";
import LOCATION_SELECTION from "../StaticWidgets/LOCATION_SELECTION";
import SEARCH_BAR from "../StaticWidgets/SEARCH_BAR";
import PROFILE_PREFERENCES from "../StaticWidgets/PROFILE_PREFERENCES";
import SELECTED_OHT from "../StaticWidgets/SELECTED_OHT";

// Complex Static Widgets

const RowItems = ({
  rowItem,
  theme,
  pageName,
  itemId,
  setActiveItemID,
  parentClass,
}: {
  rowItem: any;
  theme?: any;
  pageName?: any;
  itemId?: string;
  setActiveItemID?: (itemId: string) => void;
  parentClass?: string;
}) => {
  const visibility = React.useContext(VisibilityContext);

  useEffect(() => {
    // Check the active visible dot for scrollable row
    if (itemId) {
      const visible = visibility.isItemVisible(itemId);
      if (visible) {
        setActiveItemID(itemId);
      }
    }
  }, [itemId, setActiveItemID, visibility]);

  const history = useNavigate();
  const Map = {
    LOCATION_SELECTION,
    SEARCH_BAR,
    PROFILE_PREFERENCES,
    SELECTED_OHT,
    CarePlanWidget,
    A1,
    PI2,
    PI1,
    IC2,
    IC1,
    FC4,
    FC3,
    FC2,
    FC1,
    Twitter,
    Version,
  };

  const renderRowItem = () => {
    if (rowItem.itemType) {
      const TagName = Map[rowItem.itemType as keyof typeof Map];
      const tempItemType =
        theme.row.itemTypes.find((item: any) => item.itemType === rowItem.itemType) || {};
      return (
        <TagName
          item={rowItem}
          theme={tempItemType}
          history={history}
          pageName={pageName}
          parentClass={parentClass}
        />
      );
    }
  };
  return renderRowItem();
};

export default RowItems;
